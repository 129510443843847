@import '../colors';

.cardsList {
    border-radius: 10px;
    box-shadow: 5px 5px 30px -8px $dark-grey;
    border: 1px solid $light-grey;
    margin: 0 1.5%;
    min-width: 31%;
    padding: 15px 20px;
    background-color: white;

    header {
        border-bottom: 3px solid $pink;
        padding-bottom: 10px;
        font-size: 16px;
        color: $dark-grey;
        font-weight: 500;
        letter-spacing: 0.5px;
        display: flex;
        height: 43px;
        align-items: center;
        justify-content: space-between;

        button {
            border: 0px;
            height: 30px;
            border-radius: 20px;
            background-color: $light-grey;
            color: $blue;
            font-weight: 600;
            padding: 0 20px;
            letter-spacing: 0.5px;
            font-size: 12px;
            cursor: pointer;
        }
    }
}

.structureList {
    margin-top: 15px;
}