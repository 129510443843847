@import '../colors';

$fontSize: 14px;
$inputPadding: 5px;

.inputHolder {
    position: relative;
    box-shadow: 0px 3px 2px -2px lighten($grey, 25%);
    margin-top: $fontSize;
    
    .placeholder {
        position: absolute;
        top: $inputPadding;
        left: $inputPadding;
        font-size: $fontSize;
        letter-spacing: 0.5px;
        font-weight: 600;
        pointer-events: none;
        color: lighten($grey, 5%);
        transform: none;
        transition: font-size 0.25s, transform 0.25s;
    }
    
    input {
        display: block;
        width: 100%;
        padding: $inputPadding;
        border: none;
        box-shadow: none;
        font-size: $fontSize;
        letter-spacing: 0.5px;
        font-weight: 600;
        color: $light-blue;
        background-color: transparent;
        
        &[readonly] {
            cursor: pointer;
        }
        
        &:active, &:focus {
            outline: none;
        }
        
        &:focus + .placeholder, &.active + .placeholder {
            font-size: #{$fontSize - 4px};
            transform: translateY(#{-6px - $fontSize});
        }
        
        &:focus + .placeholder {
            color: $light-blue;
        }
    }
    
    .icon {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 10px;
        height: 10px;
    }
}