@import '../colors';

.modifyUser {
    position: relative;
    width: calc(100% - 50px);
    margin: 25px;
    padding: 20px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: white;
    
    div[class*="inputHolder"] {
        margin-bottom: 20px;
    }
}

header {
    cursor: pointer;

    h2.formHeading {
        text-align: center;
        color: lighten($light-blue, 10%);
        font-size: 16px;
        margin-bottom: 20px;
    }

    img {
        position: absolute;
        top: 25px;
        right: 25px;
        width: 15px;
        height: auto;
    }
}

.collapsedModifyUser {
    composes: modifyUser;
    height: 60px;
    overflow: hidden;
    
    img {
        transform: rotate(180deg);
    }
}

.allInputsHolder {
    display: flex;
    flex-wrap: wrap;
    
    .inputSegment {
        width: calc((100% / 3) - 10px);
        
        &:nth-child(3n + 1), &:nth-child(3n + 2) {
            margin-right: 15px;
        }
    }
    
    .phoneSegment {
        width: calc((100% / 3) - 10px);
        display: flex;
        
        &:nth-child(3n + 1), &:nth-child(3n + 2) {
            margin-right: 15px;
        }
        
        & > div:first-child {
            width: 15%;
        }
        
        & > div:last-child {
            width: 85%;
        }
    }
    
    .geoLocationSegment {
        width: calc((100% / 3) - 10px);
        
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        &:nth-child(3n + 1), &:nth-child(3n + 2) {
            margin-right: 15px;
        }
        
        & > div:first-child {
            width: 45%;
        }
        
        & > div:nth-child(2) {
            width: 45%;
        }
        
        & > img:last-child {
            height: 20px;
            margin-top: 14px;
            margin-bottom: 20px;
            cursor: pointer;
        }
    }
}

.buttonHolder {
    text-align: center;
}