@import '../colors';

.questionHolder {
    background-color: white;
    padding: 1px 0;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin-bottom: 25px;
    cursor: pointer;
}

.questionIndex {
    color: $pink;
    font-size: 15px;
    margin: 15px 30px 5px;
}

.question {
    color: $blue-grey;
    font-size: 18px;
    margin: 0 30px 15px;
}

.separator {
    display: none;
    margin-bottom: 25px;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
}

.optionsHolder {
    display: none;
    margin: 0 30px 20px;
}

.selectedQuestionHolder {
    composes: questionHolder;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    
    .question {
        margin-bottom: 25px;
    }
    
    .separator {
        display: block;
    }
    
    .optionsHolder {
        display: block;
    }
}