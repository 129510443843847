.cardsTree {
    width: 90%;
    margin: 0 auto 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    ul {
        list-style: none;
    }
}