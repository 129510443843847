@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: 'Montserrat', sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none; }

body {
  overflow: hidden; }

a {
  text-decoration: none;
  color: inherit; }

.Sidebar_SideBar__F6bLt {
  position: fixed;
  left: 0;
  top: 0;
  width: 19vw;
  height: 100vh;
  background-color: #22303d;
  overflow: hidden; }

.Sidebar_Logo__2obvQ {
  width: 100%;
  height: 90px;
  background-color: #1a2430;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .Sidebar_Logo__2obvQ img {
    margin: 0 auto;
    display: block;
    width: 80%;
    height: 70%; }

.Sidebar_NavigationLink__3D2yG {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  color: #727f88;
  -webkit-align-items: stretch;
          align-items: stretch;
  padding-left: 15px;
  border-bottom: 1px solid rgba(114, 127, 136, 0.3); }
  .Sidebar_NavigationLink__3D2yG svg {
    width: 18px;
    height: 30px;
    -webkit-align-self: center;
            align-self: center;
    filter: brightness(50%);
    -webkit-filter: brightness(50%); }
  .Sidebar_NavigationLink__3D2yG span {
    margin-left: 15px;
    border-left: 1px solid rgba(114, 127, 136, 0.3);
    padding: 17px 0;
    font-size: 12px;
    font-weight: 500;
    padding-left: 15px; }

.Sidebar_active__WgMv6 {
  color: rgba(255, 255, 255, 0.7);
  background-color: #283a48; }
  .Sidebar_active__WgMv6 svg {
    filter: brightness(70%);
    -webkit-filter: brightness(70%); }

.Sidebar_NavHeader__3EsB5 {
  border-bottom: 1px solid rgba(114, 127, 136, 0.3);
  font-size: 12px;
  color: #727f88;
  font-weight: 600;
  padding: 17px 0 17px 15px; }

.PageHeader_PageHeader__3akou {
  position: fixed;
  z-index: 1;
  left: 19vw;
  top: 0;
  width: 81vw;
  height: 90px;
  background-color: #22303d;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
          align-items: stretch; }

.PageHeader_SearchBar__2EqCu {
  -webkit-align-self: center;
          align-self: center;
  width: 50%;
  margin-right: auto; }
  .PageHeader_SearchBar__2EqCu img {
    margin-left: 40px;
    width: 20px;
    vertical-align: middle;
    -webkit-filter: brightness(50%);
    filter: brightness(50%); }
  .PageHeader_SearchBar__2EqCu input {
    height: 100%;
    width: 80%;
    margin-left: 30px;
    height: 90px;
    background-color: transparent;
    border: 0px;
    color: #949599;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px; }

.PageHeader_ProfileUtilitiesHolder__kf-AZ {
  -webkit-align-self: center;
          align-self: center;
  display: -webkit-flex;
  display: flex;
  font-weight: 500;
  letter-spacing: 0.5px;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .PageHeader_ProfileUtilitiesHolder__kf-AZ .PageHeader_name__1pylN {
    color: rgba(255, 255, 255, 0.7);
    font-size: 15px;
    margin-right: 15px;
    -webkit-align-self: center;
            align-self: center;
    display: block; }
  .PageHeader_ProfileUtilitiesHolder__kf-AZ .PageHeader_nameRepresentation__3wWzy {
    display: block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.7);
    color: white;
    font-size: 18px;
    -webkit-align-self: center;
            align-self: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-right: 20px; }
  .PageHeader_ProfileUtilitiesHolder__kf-AZ .PageHeader_icon__1OOfZ {
    display: -webkit-flex;
    display: flex;
    width: 80px;
    height: 90px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer;
    position: relative; }
    .PageHeader_ProfileUtilitiesHolder__kf-AZ .PageHeader_icon__1OOfZ.PageHeader_active__ZfxBg::before {
      content: '';
      top: 37px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      left: 43px;
      background-color: #ee0c6e;
      position: absolute;
      z-index: 1; }
    .PageHeader_ProfileUtilitiesHolder__kf-AZ .PageHeader_icon__1OOfZ.PageHeader_bell__9ab2V {
      box-shadow: 1px 0px 5px 0px #1a2430; }
      .PageHeader_ProfileUtilitiesHolder__kf-AZ .PageHeader_icon__1OOfZ.PageHeader_bell__9ab2V img {
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        -webkit-filter: brightness(80%);
        filter: brightness(80%); }
    .PageHeader_ProfileUtilitiesHolder__kf-AZ .PageHeader_icon__1OOfZ.PageHeader_logout__2cWju img {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
    .PageHeader_ProfileUtilitiesHolder__kf-AZ .PageHeader_icon__1OOfZ img {
      display: block;
      height: 25px; }

.Structure_FocusSpace__2iwxg {
  width: calc(100vw - 19vw);
  height: calc(100vh - 90px - 136px);
  margin-top: 90px;
  margin-left: auto;
  overflow: auto;
  position: relative;
  background-color: #f9fafc; }

.Tabs_Tabs__eDtEt {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 15px auto 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: center;
          align-self: center;
  background-color: #e7e7e7;
  border-radius: 40px; }
  .Tabs_Tabs__eDtEt .Tabs_Tab__WVmaC {
    display: block;
    border-radius: 40px;
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 600;
    padding: 8px 0;
    min-width: 200px;
    text-align: center;
    color: #595959;
    cursor: pointer; }
    .Tabs_Tabs__eDtEt .Tabs_Tab__WVmaC.Tabs_active__2sVAI {
      background-color: #22303d;
      color: white;
      pointer-events: none; }

.Levels_cardsTree__2S0b3 {
  width: 90%;
  margin: 0 auto 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-start;
          align-items: flex-start; }
  .Levels_cardsTree__2S0b3 ul {
    list-style: none; }

.CardsList_cardsList__1ukn4 {
  border-radius: 10px;
  box-shadow: 5px 5px 30px -8px #595959;
  border: 1px solid #e7e7e7;
  margin: 0 1.5%;
  min-width: 31%;
  padding: 15px 20px;
  background-color: white; }
  .CardsList_cardsList__1ukn4 header {
    border-bottom: 3px solid #ee0c6e;
    padding-bottom: 10px;
    font-size: 16px;
    color: #595959;
    font-weight: 500;
    letter-spacing: 0.5px;
    display: -webkit-flex;
    display: flex;
    height: 43px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    .CardsList_cardsList__1ukn4 header button {
      border: 0px;
      height: 30px;
      border-radius: 20px;
      background-color: #e7e7e7;
      color: #22303d;
      font-weight: 600;
      padding: 0 20px;
      letter-spacing: 0.5px;
      font-size: 12px;
      cursor: pointer; }

.CardsList_structureList__CNpaS {
  margin-top: 15px; }

.Card_normal__1kHGj {
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  overflow: hidden;
  height: 50px;
  background-color: #e7e7e7;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 15px;
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
  cursor: pointer; }
  .Card_normal__1kHGj:hover {
    box-shadow: 2px 2px 5px 0px #949599; }
  .Card_normal__1kHGj .Card_index__3f-tx {
    font-weight: 700;
    width: 50px;
    text-align: center;
    color: #949599;
    font-size: 14px; }
  .Card_normal__1kHGj .Card_details__xTwgx {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    background-color: #f9fafc;
    font-weight: 500;
    border-radius: 10px;
    letter-spacing: 0.5px;
    overflow: hidden;
    -webkit-align-self: stretch;
            align-self: stretch;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: center;
            justify-content: center; }
    .Card_normal__1kHGj .Card_details__xTwgx .Card_title__3GVKx {
      width: 100%;
      padding-bottom: 3px;
      padding-left: 20px;
      color: #595959;
      font-size: 14px; }
    .Card_normal__1kHGj .Card_details__xTwgx .Card_subTitle__KhQZd {
      width: 100%;
      border-top: 1px solid #efefef;
      padding-top: 3px;
      padding-left: 20px;
      color: #949599;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
  .Card_normal__1kHGj .Card_delete__QU98d {
    width: 40px;
    height: 50px;
    display: none;
    background-color: #f9fafc;
    font-weight: 500;
    border-radius: 10px; }
    .Card_normal__1kHGj .Card_delete__QU98d img {
      width: 20px;
      display: block;
      position: relative;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .Card_normal__1kHGj .Card_toggleHolder__1zsyr {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 60px;
    height: 50px;
    background-color: transparent; }

.Card_active__sgwEK {
  background-color: #ee0c6e;
  box-shadow: 2px 2px 5px 0px #595959; }
  .Card_active__sgwEK:hover {
    box-shadow: 2px 2px 6px 1.5px rgba(89, 89, 89, 0.8); }
  .Card_active__sgwEK .Card_index__3f-tx {
    color: white; }
  .Card_active__sgwEK .Card_details__xTwgx {
    background-color: #22303d;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
    .Card_active__sgwEK .Card_details__xTwgx .Card_title__3GVKx {
      color: white; }
    .Card_active__sgwEK .Card_details__xTwgx .Card_subTitle__KhQZd {
      color: #949599;
      border-color: #595959; }
  .Card_active__sgwEK .Card_delete__QU98d {
    display: block;
    background-color: #22303d;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 1px solid #595959; }

.CardForm_addLevel___gSv3 {
  background-color: #f9fafc;
  padding: 8px;
  box-shadow: 0px 0px 2px #d5d6d7; }
  .CardForm_addLevel___gSv3 div[class*="inputHolder"] {
    background-color: white;
    margin-bottom: 20px;
    box-shadow: 0px 0px 2px #d5d6d7; }

.CardForm_submitFormButton__3R9vp {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  margin-top: -8px;
  box-shadow: none;
  background-color: #22303d;
  color: white;
  text-align: center;
  font-size: 13px;
  cursor: pointer; }
  .CardForm_submitFormButton__3R9vp:focus, .CardForm_submitFormButton__3R9vp:active {
    outline: none; }

.InputText_inputHolder__3njbz {
  position: relative;
  box-shadow: 0px 3px 2px -2px #d5d6d7;
  margin-top: 14px; }
  .InputText_inputHolder__3njbz .InputText_placeholder__31FVL {
    position: absolute;
    top: 5px;
    left: 5px;
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 600;
    pointer-events: none;
    color: #a1a2a5;
    -webkit-transform: none;
            transform: none;
    -webkit-transition: font-size 0.25s, -webkit-transform 0.25s;
    transition: font-size 0.25s, -webkit-transform 0.25s;
    transition: font-size 0.25s, transform 0.25s;
    transition: font-size 0.25s, transform 0.25s, -webkit-transform 0.25s; }
  .InputText_inputHolder__3njbz input {
    display: block;
    width: 100%;
    padding: 5px;
    border: none;
    box-shadow: none;
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 600;
    color: #283a48;
    background-color: transparent; }
    .InputText_inputHolder__3njbz input[readonly] {
      cursor: pointer; }
    .InputText_inputHolder__3njbz input:active, .InputText_inputHolder__3njbz input:focus {
      outline: none; }
    .InputText_inputHolder__3njbz input:focus + .InputText_placeholder__31FVL, .InputText_inputHolder__3njbz input.InputText_active__3egOB + .InputText_placeholder__31FVL {
      font-size: 10px;
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px); }
    .InputText_inputHolder__3njbz input:focus + .InputText_placeholder__31FVL {
      color: #283a48; }
  .InputText_inputHolder__3njbz .InputText_icon__liT0r {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 10px;
    height: 10px; }

.OptionsList_optionsList__IJWfT {
  position: absolute;
  top: calc(100% + 2px);
  left: 0px;
  right: 0px;
  z-index: 1;
  background-color: white;
  box-shadow: 0px 0px 4px #d5d6d7; }

.Option_option__2tzJY {
  padding: 12px 10px;
  font-size: 13px;
  border-bottom: 1px solid #d5d6d7; }
  .Option_option__2tzJY:hover {
    background-color: #283a48;
    color: white;
    cursor: pointer; }
  .Option_option__2tzJY em {
    font-style: normal;
    color: #ee0c6e;
    font-weight: bold; }

.Locations_cardsTree__j1hUb {
  width: 90%;
  margin: 0 auto 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-start;
          align-items: flex-start; }
  .Locations_cardsTree__j1hUb ul {
    list-style: none; }

.UserModify_modifyUser__TqCnm {
  position: relative;
  width: calc(100% - 50px);
  margin: 25px;
  padding: 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: white; }
  .UserModify_modifyUser__TqCnm div[class*="inputHolder"] {
    margin-bottom: 20px; }

header {
  cursor: pointer; }
  header h2.UserModify_formHeading__e-rKY {
    text-align: center;
    color: #3a5469;
    font-size: 16px;
    margin-bottom: 20px; }
  header img {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 15px;
    height: auto; }

.UserModify_collapsedModifyUser__3R8lF {
  height: 60px;
  overflow: hidden; }
  .UserModify_collapsedModifyUser__3R8lF img {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

.UserModify_allInputsHolder__2re2J {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  .UserModify_allInputsHolder__2re2J .UserModify_inputSegment__22-N1 {
    width: calc((100% / 3) - 10px); }
    .UserModify_allInputsHolder__2re2J .UserModify_inputSegment__22-N1:nth-child(3n + 1), .UserModify_allInputsHolder__2re2J .UserModify_inputSegment__22-N1:nth-child(3n + 2) {
      margin-right: 15px; }
  .UserModify_allInputsHolder__2re2J .UserModify_phoneSegment__VPixW {
    width: calc((100% / 3) - 10px);
    display: -webkit-flex;
    display: flex; }
    .UserModify_allInputsHolder__2re2J .UserModify_phoneSegment__VPixW:nth-child(3n + 1), .UserModify_allInputsHolder__2re2J .UserModify_phoneSegment__VPixW:nth-child(3n + 2) {
      margin-right: 15px; }
    .UserModify_allInputsHolder__2re2J .UserModify_phoneSegment__VPixW > div:first-child {
      width: 15%; }
    .UserModify_allInputsHolder__2re2J .UserModify_phoneSegment__VPixW > div:last-child {
      width: 85%; }
  .UserModify_allInputsHolder__2re2J .UserModify_geoLocationSegment__1tBR- {
    width: calc((100% / 3) - 10px);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center; }
    .UserModify_allInputsHolder__2re2J .UserModify_geoLocationSegment__1tBR-:nth-child(3n + 1), .UserModify_allInputsHolder__2re2J .UserModify_geoLocationSegment__1tBR-:nth-child(3n + 2) {
      margin-right: 15px; }
    .UserModify_allInputsHolder__2re2J .UserModify_geoLocationSegment__1tBR- > div:first-child {
      width: 45%; }
    .UserModify_allInputsHolder__2re2J .UserModify_geoLocationSegment__1tBR- > div:nth-child(2) {
      width: 45%; }
    .UserModify_allInputsHolder__2re2J .UserModify_geoLocationSegment__1tBR- > img:last-child {
      height: 20px;
      margin-top: 14px;
      margin-bottom: 20px;
      cursor: pointer; }

.UserModify_buttonHolder__3Cri2 {
  text-align: center; }

.Button_button__2llLP {
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 6px 35px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 17px;
  cursor: pointer; }
  .Button_button__2llLP:active, .Button_button__2llLP:focus {
    outline: none; }

.Button_primaryButton__1dW5m {
  color: white;
  background-color: #22303d; }

.Button_outlineButton__1Eu-h {
  color: #22303d;
  border: 1px solid #22303d; }

.UsersTable_table__3wDVX {
  table-layout: fixed;
  width: 100%;
  position: relative;
  width: calc(100% - 50px);
  margin: 25px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: white;
  text-align: center; }
  .UsersTable_table__3wDVX thead {
    color: white; }
    .UsersTable_table__3wDVX thead th {
      background-color: #717f8a; }
    .UsersTable_table__3wDVX thead th:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px; }
    .UsersTable_table__3wDVX thead th:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px; }
  .UsersTable_table__3wDVX tbody tr:not(:last-child) td {
    border-bottom: 1px solid #e6e6e6; }
  .UsersTable_table__3wDVX th, .UsersTable_table__3wDVX td {
    padding: 15px 0;
    font-size: 14px;
    font-weight: normal; }

.UsersTable_slNo__1a3Vk {
  width: 12%; }

.UsersTable_username__3oKCK {
  width: 15%; }

.UsersTable_level__2ZLiG {
  width: 15%; }

.UsersTable_entity__1EsZx {
  width: 15%; }

.UsersTable_role__3-t-r {
  width: 15%; }

.UsersTable_manager__lG0sX {
  width: 13%; }

.UsersTable_actions__21KfZ {
  width: 15%;
  text-align: right;
  padding-right: 35px; }
  .UsersTable_actions__21KfZ img {
    width: 20px;
    margin-right: 30px; }

.Toggle_toggle__VaPzN {
  width: 40px;
  height: 18px;
  border-radius: 8px;
  -webkit-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
  cursor: pointer; }
  .Toggle_toggle__VaPzN .Toggle_slider__1j8GF {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-transition: -webkit-transform 0.3s linear;
    transition: -webkit-transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear; }

.Toggle_on__1Q9Kg {
  background-color: #8bc542; }

.Toggle_off__1rcUC {
  background-color: #bb4950; }
  .Toggle_off__1rcUC .Toggle_slider__1j8GF {
    -webkit-transform: scale(1.1) translateX(19px);
            transform: scale(1.1) translateX(19px); }

.Translations_LanguageTranslations__2KzYl {
  position: fixed;
  width: 80.9vw;
  left: 19.1vw;
  bottom: 0;
  z-index: 1;
  box-shadow: 0px 0px 5px 0px #949599; }
  .Translations_LanguageTranslations__2KzYl header {
    background-color: #e7e7e7;
    color: #595959;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-align: center;
    padding: 10px 0;
    font-size: 16px; }
  .Translations_LanguageTranslations__2KzYl .Translations_TranslationsHolder__2CCLz {
    background-color: white;
    display: -webkit-flex;
    display: flex;
    padding: 20px 30px;
    width: 100%;
    overflow-x: scroll; }
  .Translations_LanguageTranslations__2KzYl .Translations_InputHolder__cGU3o {
    min-width: 25%;
    position: relative;
    border: 1px solid #949599;
    border-radius: 5px;
    height: 40px;
    margin-right: 30px; }
    .Translations_LanguageTranslations__2KzYl .Translations_InputHolder__cGU3o label {
      position: absolute;
      left: 10px;
      top: -10px;
      padding: 0 10px;
      background-color: white;
      color: #949599;
      font-size: 14px;
      letter-spacing: 0.5px; }
    .Translations_LanguageTranslations__2KzYl .Translations_InputHolder__cGU3o input {
      padding-left: 20px;
      border: 0px;
      display: block;
      height: 100%;
      background-color: transparent;
      font-weight: 500;
      font-size: 14px;
      color: #595959; }

.Todos_FocusSpace__1jvZP {
  width: calc(100vw - 19vw);
  height: calc(100vh - 90px - 136px);
  margin-top: 90px;
  margin-left: auto;
  overflow: auto;
  position: relative;
  background-color: #f9fafc; }

.Todos_cardsTree__18cFy {
  width: 90%;
  margin: 0 auto 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start; }
  .Todos_cardsTree__18cFy ul {
    list-style: none; }

.Questionnaire_FocusSpace__1OUap {
  display: -webkit-flex;
  display: flex;
  width: calc(100vw - 19vw);
  height: calc(100vh - 90px - 136px);
  margin-top: 90px;
  margin-left: auto;
  overflow: auto;
  position: relative;
  background-color: #f9fafc; }
  .Questionnaire_FocusSpace__1OUap .Questionnaire_questionsList__1mCbe {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    height: 100%;
    overflow: auto;
    padding: 25px 35px; }
  .Questionnaire_FocusSpace__1OUap .Questionnaire_selectedQuestion__3DiTp {
    width: 350px;
    height: 100%;
    overflow: auto; }

.Questionnaire_cardsTree__3LKOc {
  width: 90%;
  margin: 0 auto 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start; }
  .Questionnaire_cardsTree__3LKOc ul {
    list-style: none; }

.Questionnaire_selectedQuestion__3DiTp {
  padding: 25px 30px;
  background-color: #f4f4f4;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); }

.Question_questionHolder__1PNT4 {
  background-color: white;
  padding: 1px 0;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-bottom: 25px;
  cursor: pointer; }

.Question_questionIndex__3BGsK {
  color: #ee0c6e;
  font-size: 15px;
  margin: 15px 30px 5px; }

.Question_question__2H6Br {
  color: #717f8a;
  font-size: 18px;
  margin: 0 30px 15px; }

.Question_separator__1aSjs {
  display: none;
  margin-bottom: 25px;
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1); }

.Question_optionsHolder__1RocQ {
  display: none;
  margin: 0 30px 20px; }

.Question_selectedQuestionHolder__1lsnW {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); }
  .Question_selectedQuestionHolder__1lsnW .Question_question__2H6Br {
    margin-bottom: 25px; }
  .Question_selectedQuestionHolder__1lsnW .Question_separator__1aSjs {
    display: block; }
  .Question_selectedQuestionHolder__1lsnW .Question_optionsHolder__1RocQ {
    display: block; }

.OptionView_optionView__3GiAh {
  margin-bottom: 15px;
  border-radius: 5px;
  overflow: hidden; }

.OptionView_optionTextHolder__31QgZ {
  display: -webkit-flex;
  display: flex;
  color: #717f8a; }
  .OptionView_optionTextHolder__31QgZ .OptionView_optionText___2WG- {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    background-color: #f9fafc;
    padding: 15px 20px; }
  .OptionView_optionTextHolder__31QgZ .OptionView_nextQuestion__2nVHS {
    background-color: #e8ecf4;
    padding: 15px 20px; }

.OptionView_todoText__IXrGG {
  background-color: #f2f4f9;
  padding: 15px 20px 15px 40px;
  color: #949599; }

.QuestionDetails_questionDetails__7gjEX div[class*="InputText"] {
  margin-bottom: 25px; }

.QuestionDetails_questionIndex__LLNF- {
  font-size: 18px;
  margin-bottom: 35px;
  color: #595959; }

.Workflows_FocusSpace__3-Lxx {
  width: calc(100vw - 19vw);
  height: calc(100vh - 90px - 136px);
  margin-top: 90px;
  margin-left: auto;
  overflow: auto;
  position: relative;
  background-color: #f9fafc; }

.Workflows_workflowsHolder__2sKmP {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  list-style: none;
  padding: 0; }
  .Workflows_workflowsHolder__2sKmP li[class*="Card"] {
    -webkit-flex-basis: 24%;
            flex-basis: 24%;
    margin-right: 1%; }

.Workflows_allWorkflows__1O3Px {
  padding: 20px 15px;
  background-color: white;
  margin-bottom: 20px; }
  .Workflows_allWorkflows__1O3Px h2 {
    font-size: 16px;
    color: #595959;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-bottom: 15px; }

.Workflows_cardsTree__P4TGf {
  width: 90%;
  margin: 0 auto 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-start;
          align-items: flex-start; }
  .Workflows_cardsTree__P4TGf ul {
    list-style: none; }

.OrganizationProfile_OrganisationProfile__3sg0X {
  width: calc(100vw - 19vw);
  height: calc(100vh - 90px);
  margin-top: 90px;
  margin-left: auto;
  overflow: auto;
  position: relative;
  background-color: #f9fafc; }
  .OrganizationProfile_OrganisationProfile__3sg0X h2 {
    width: 95%;
    margin: 20px auto;
    font-weight: 600;
    color: #595959;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    font-size: 20px; }
    .OrganizationProfile_OrganisationProfile__3sg0X h2 button {
      font-size: 15px;
      color: #283a48;
      font-weight: 500;
      background-color: transparent;
      border: 0px; }

.OrganizationProfile_Card__3E2gc {
  display: -webkit-flex;
  display: flex;
  margin: 0 auto;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding: 25px 30px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-radius: 10px;
  box-shadow: 5px 5px 30px -8px #595959;
  border: 1px solid #e7e7e7;
  width: 95%;
  margin: 0 auto 20px;
  background-color: white; }
  .OrganizationProfile_Card__3E2gc .OrganizationProfile_UpgradeButton__3Murh {
    padding: 0 30px;
    -webkit-align-self: center;
            align-self: center;
    border-radius: 30px;
    background-color: #283a48;
    border: 0;
    color: white;
    font-size: 12px;
    font-weight: 600;
    height: 35px; }

.OrganizationProfile_ProfileImageHolder__25zYL {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  display: block; }
  .OrganizationProfile_ProfileImageHolder__25zYL:hover::before, .OrganizationProfile_ProfileImageHolder__25zYL:hover::after {
    opacity: 1; }
  .OrganizationProfile_ProfileImageHolder__25zYL::before, .OrganizationProfile_ProfileImageHolder__25zYL::after {
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
    cursor: pointer; }
  .OrganizationProfile_ProfileImageHolder__25zYL::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    border-radius: inherit;
    background-color: rgba(231, 231, 231, 0.8); }
  .OrganizationProfile_ProfileImageHolder__25zYL::after {
    content: '';
    background-image: url(/static/media/uploading-archive.1f681d0a.svg);
    position: absolute;
    left: 40%;
    top: 40%;
    z-index: 1;
    width: 30px;
    height: 30px;
    opacity: 0;
    background-size: contain;
    -webkit-transform: scale(0.7);
            transform: scale(0.7); }
  .OrganizationProfile_ProfileImageHolder__25zYL img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block; }
  .OrganizationProfile_ProfileImageHolder__25zYL input {
    opacity: 0;
    position: absolute;
    left: 0;
    z-index: -1;
    width: 0px;
    height: 0px; }

.OrganizationProfile_ProfileDataHolder__yOohH {
  width: calc(100% - 170px);
  margin-left: auto;
  -webkit-align-self: center;
          align-self: center;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding-left: 30px; }

.OrganizationProfile_ContentFragment__1JNJf {
  width: 33%;
  margin-bottom: 20px; }
  .OrganizationProfile_ContentFragment__1JNJf label {
    position: relative;
    font-size: 13px;
    font-weight: 600;
    color: #595959; }
    .OrganizationProfile_ContentFragment__1JNJf label picture {
      width: 15px;
      vertical-align: middle;
      margin-right: 7px;
      opacity: 0.7;
      display: inline-block; }
      .OrganizationProfile_ContentFragment__1JNJf label picture img {
        width: 100%;
        height: 100%; }
  .OrganizationProfile_ContentFragment__1JNJf .OrganizationProfile_InputValue__3pmhV {
    color: #949599;
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    line-height: 1.5; }

.OrganizationProfile_Address__3YkNS {
  width: 50%; }
  .OrganizationProfile_Address__3YkNS .OrganizationProfile_InputValue__3pmhV {
    width: 80%; }

.ColorPicker_ColorProgress__1CNpN {
  width: 300px;
  height: 15px;
  margin-bottom: 15px; }
  .ColorPicker_ColorProgress__1CNpN input {
    display: block;
    width: 100%;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }

.ColorPicker_HueSelector__2-tOS {
  background-image: -webkit-linear-gradient(left, red 0%, yellow 17%, lime 33%, cyan 50%, blue 67%, magenta 83%, red 100%);
  background-image: linear-gradient(to right, red 0%, yellow 17%, lime 33%, cyan 50%, blue 67%, magenta 83%, red 100%); }

.ColorPicker_SaturationSelector__36Ox6 {
  background-image: -webkit-linear-gradient(left, gray 0%, lime 100%);
  background-image: linear-gradient(to right, gray 0%, lime 100%); }

.ColorPicker_BrightnessSelector__3ue7F {
  background-image: -webkit-linear-gradient(left, black 0%, lime 50%, white 100%);
  background-image: linear-gradient(to right, black 0%, lime 50%, white 100%); }

.ColorPicker_ColorComponentHolder__1XsLR {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.ColorPicker_ProgressBars__2MV0K {
  display: inline-block;
  vertical-align: top;
  width: 300px;
  margin-right: 30px; }

.ColorPicker_SelectedColor__1pYt_ {
  display: inline-block;
  vertical-align: top;
  width: 150px;
  height: 80px;
  line-height: 80px;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  border: none;
  box-shadow: none;
  padding: 0px;
  text-align: center;
  font-size: 18px;
  color: white;
  font-weight: bold;
  text-shadow: 0px 0px 4px black; }
  .ColorPicker_SelectedColor__1pYt_:focus {
    outline: none; }

