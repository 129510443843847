@import '../colors';

.OrganisationProfile {
    width: calc(100vw - 19vw);
    height: calc(100vh - 90px);
    margin-top: 90px;
    margin-left: auto;
    overflow: auto;
    position: relative;
    background-color: #f9fafc;
    
    h2 {
        width: 95%;
        margin: 20px auto;
        font-weight: 600;
        color: $dark-grey;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 20px;

        button {
            font-size: 15px;
            color: $light-blue;
            font-weight: 500;
            background-color: transparent;
            border: 0px;
        }
    }
}

.Card {
    display: flex;
    margin: 0 auto;
    align-items: flex-start;
    padding: 25px 30px;
    justify-content: space-between;

    border-radius: 10px;
    box-shadow: 5px 5px 30px -8px $dark-grey;
    border: 1px solid $light-grey;
    width: 95%;
    margin: 0 auto 20px;
    background-color: white;

    .UpgradeButton {
        padding: 0 30px;
        align-self: center;
        border-radius: 30px;
        background-color: $light-blue;
        border: 0;
        color: white;
        font-size: 12px;
        font-weight: 600;
        height: 35px;
    }

}


.ProfileImageHolder {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    display: block;

    &:hover {
        &::before, &::after {
            opacity: 1;
        }
    }

    &::before, &::after {
        transition: opacity 0.3s;
        cursor: pointer;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
        border-radius: inherit;
        background-color: transparentize($light-grey, 0.2);
    }

    &::after {
        content: '';
        background-image: url('./uploading-archive.svg');
        position: absolute;
        left: 40%;
        top: 40%;
        z-index: 1;
        width: 30px;
        height: 30px;
        opacity: 0;
        background-size: contain;
        transform: scale(0.7);
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
    }

    input {
        opacity: 0;
        position: absolute;
        left: 0;
        z-index: -1;
        width: 0px;
        height: 0px;
    }
}

.ProfileDataHolder {
    width: calc(100% - 170px);
    margin-left: auto;
    align-self: center;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: 30px;
}

.ContentFragment {
    width: 33%;
    margin-bottom: 20px;

    label {
        position: relative;
        picture {
            width: 15px;
            vertical-align: middle;
            margin-right: 7px;
            opacity: 0.7;
            display: inline-block;
            img {
                width: 100%;
                height: 100%;
            }
        }
        font-size: 13px;
        font-weight: 600;
        color: $dark-grey;
    }

    .InputValue {
        color: $grey;
        font-size: 16px;
        font-weight: 500;
        margin-top: 10px;
        line-height: 1.5;
    }
}

.Address {
    composes: ContentFragment;
    width: 50%;

    .InputValue {
        width: 80%;
    }
}