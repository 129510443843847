@import '../colors';

.SideBar {
    position: fixed;
    left: 0;
    top: 0;
    width: 19vw;
    height: 100vh;
    background-color: $blue;
    overflow: hidden;

}

.Logo {
    width: 100%;
    height: 90px;
    background-color: $dark-blue;
    display: flex;
    align-items: center;

    img {
        margin: 0 auto;
        display: block;
        width: 80%;
        height: 70%;
    }
}


.NavigationLink {
    display: flex;
    width: 100%;
    color: $lighter-blue;
    align-items: stretch;
    padding-left: 15px;
    border-bottom: 1px solid rgba($lighter-blue, 0.3);

    svg {
        width: 18px;
        height: 30px;
        align-self: center;
        filter: brightness(50%);
        -webkit-filter: brightness(50%);
    }
    
    span {
        margin-left: 15px;
        border-left: 1px solid rgba($lighter-blue, 0.3);
        padding: 17px 0;
        font-size: 12px;
        font-weight: 500;
        padding-left: 15px;
    }
}

.active {
    color: transparentize(white, 0.3);
    background-color: $light-blue;

    svg {
        filter: brightness(70%);
        -webkit-filter: brightness(70%);
    }
}

.NavHeader {
    border-bottom: 1px solid rgba($lighter-blue, 0.3);
    font-size: 12px;
    color: $lighter-blue;
    font-weight: 600;
    padding: 17px 0 17px 15px;
}