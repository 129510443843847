@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700');
@import './colors';

* {
    font-family: 'Montserrat', sans-serif;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: none;
}

body {
    overflow: hidden;
}

a {
    text-decoration: none;
    color: inherit;
}
