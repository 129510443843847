@import '../colors';

.optionsList {
    position: absolute;
    top: calc(100% + 2px);
    left: 0px;
    right: 0px;
    z-index: 1;
    background-color: white;
    box-shadow: 0px 0px 4px lighten($grey, 25%);
}