@import '../colors';

.questionDetails {
    
    div[class*="InputText"] {
        margin-bottom: 25px;
    }
}

.questionIndex {
    font-size: 18px;
    margin-bottom: 35px;
    color: $dark-grey;
}