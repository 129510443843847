@import '../colors';

.PageHeader {
    position: fixed;
    z-index: 1;
    left: 19vw;
    top: 0;
    width: 81vw;
    height: 90px;
    background-color: $blue;
    overflow: hidden;
    display: flex;
    align-items: stretch;
}

.SearchBar {
    align-self: center;
    width: 50%;
    margin-right: auto;

    img {
        margin-left: 40px;
        width: 20px;
        vertical-align: middle;
        -webkit-filter: brightness(50%);
        filter: brightness(50%);
    }

    input {
        height: 100%;
        width: 80%;
        margin-left: 30px;
        height: 90px;
        background-color: transparent;
        border: 0px;
        color: $grey;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.5px;
    }
}

.ProfileUtilitiesHolder {
    align-self: center;
    display: flex;
    font-weight: 500;
    letter-spacing: 0.5px;
    align-items: stretch;

    .name {
        color: rgba(white, 0.7);
        font-size: 15px;
        margin-right: 15px;
        align-self: center;
        display: block;
    }

    .nameRepresentation {
        display: block;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: rgba(white, 0.7);
        color: white;
        font-size: 18px;
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
    }

    .icon {
        display: flex;
        width: 80px;
        height: 90px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;

        &.active {
            &::before {
                content: '';
                top: 37px;
                width: 13px;
                height: 13px;
                border-radius: 50%;
                left: 43px;
                background-color: $pink;
                position: absolute;
                z-index: 1;
            }
        }
    
        &.bell {
            box-shadow: 1px 0px 5px 0px $dark-blue;

            img {
                transform: rotate(45deg);
                -webkit-filter: brightness(80%);
                filter: brightness(80%);
            }
        }

        &.logout {
            img {
                transform: rotate(180deg);
            }
        }

        img {
            display: block;
            height: 25px;
        }
    }
}