@import '../colors';

.optionView {
    margin-bottom: 15px;
    border-radius: 5px;
    overflow: hidden;
}
    
.optionTextHolder {
    display: flex;
    color: $blue-grey;
    
    .optionText {
        flex-grow: 1;
        background-color: $lighter-grey;
        padding: 15px 20px;
    }
    
    .nextQuestion {
        background-color: darken($lighter-grey, 5%);
        padding: 15px 20px;
    }
}

.todoText {
    background-color: darken($lighter-grey, 2%);
    padding: 15px 20px 15px 40px;
    color: $grey;
}