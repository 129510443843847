@import '../colors';

.table {
    table-layout: fixed;
    width: 100%;
    
    position: relative;
    width: calc(100% - 50px);
    margin: 25px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: white;
    text-align: center;
    
    thead {
        color: white;
        
        th {
            background-color: $blue-grey;
        }
        
        th:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        
        th:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
    
    tbody {
        tr:not(:last-child) td {
            border-bottom: 1px solid hsl(0, 0%, 90%);
        }
    }
    
    th, td {
        padding: 15px 0;
        font-size: 14px;
        font-weight: normal;
    }
}

.slNo {
    width: 12%;
}

.username {
    width: 15%;
}

.level {
    width: 15%;
}

.entity {
    width: 15%;
}

.role {
    width: 15%;
}

.manager {
    width: 13%;
}

.actions {
    width: 15%;
    text-align: right;
    padding-right: 35px;
    
    img {
        width: 20px;
        margin-right: 30px;
    }
}