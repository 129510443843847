@import '../colors';

.button {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 6px 35px;
    text-align: center;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 17px;
    cursor: pointer;
    
    &:active, &:focus {
        outline: none;
    }
}

.primaryButton {
    composes: button;
    color: white;
    background-color: $blue;
}

.outlineButton {
    composes: button;
    color: $blue;
    border: 1px solid $blue;
}