@import '../colors';

.normal {
    border-radius: 10px;
    display: flex;
    width: 100%;
    overflow: hidden;
    height: 50px;
    background-color: $light-grey;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    transition: box-shadow 0.3s;
    cursor: pointer;

    &:hover {
        box-shadow: 2px 2px 5px 0px $grey;
    }

    .index {
        font-weight: 700;
        width: 50px;
        text-align: center;
        color: $grey;
        font-size: 14px;
    }

    .details {
        flex-grow: 1;
        background-color: $lighter-grey;
        font-weight: 500;
        border-radius: 10px;
        letter-spacing: 0.5px;
        overflow: hidden;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        
        .title {
            width: 100%;
            padding-bottom: 3px;
            padding-left: 20px;
            color: $dark-grey;
            font-size: 14px;
        }
        
        .subTitle {
            width: 100%;
            border-top: 1px solid lighten($light-grey, 3%);
            padding-top: 3px;
            padding-left: 20px;
            color: $grey;
            font-size: 12px;
            
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    
    .delete {
        width: 40px;
        height: 50px;
        display: none;
        background-color: $lighter-grey;
        font-weight: 500;
        border-radius: 10px;
        
        img {
            width: 20px;
            display: block;
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    
    .toggleHolder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 50px;
        background-color: transparent;
    }
}

.active {
    composes: normal;
    background-color: $pink;
    box-shadow: 2px 2px 5px 0px $dark-grey;

    &:hover {
        box-shadow: 2px 2px 6px 1.5px transparentize($dark-grey, 0.2);
    }

    .index {
        color: white;
    }

    .details {
        background-color: $blue;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        
        .title {
            color: white;
        }
        
        .subTitle {
            color: $grey;
            border-color: $dark-grey;
        }
    }
    
    .delete {
        display: block;
        background-color: $blue;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 1px solid $dark-grey;
    }
}