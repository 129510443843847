@import '../colors';

.FocusSpace {
    display: flex;
    width: calc(100vw - 19vw);
    height: calc(100vh - 90px - 136px);
    margin-top: 90px;
    margin-left: auto;
    overflow: auto;
    position: relative;
    background-color: #f9fafc;
    
    .questionsList {
        flex-grow: 1;
        height: 100%;
        overflow: auto;
        padding: 25px 35px;
    }
    
    .selectedQuestion {
        width: 350px;
        height: 100%;
        overflow: auto;
    }
}

.cardsTree {
    width: 90%;
    margin: 0 auto 40px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    ul {
        list-style: none;
    }
}

.selectedQuestion {
    padding: 25px 30px;
    background-color: lighten($light-grey, 5%);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}