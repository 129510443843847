@import '../colors';

.FocusSpace {
    width: calc(100vw - 19vw);
    height: calc(100vh - 90px - 136px);
    margin-top: 90px;
    margin-left: auto;
    overflow: auto;
    position: relative;
    background-color: #f9fafc;
}