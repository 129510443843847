@import '../colors';

.FocusSpace {
    width: calc(100vw - 19vw);
    height: calc(100vh - 90px - 136px);
    margin-top: 90px;
    margin-left: auto;
    overflow: auto;
    position: relative;
    background-color: #f9fafc;
}

.workflowsHolder {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    
    list-style: none;
    padding: 0;
    
    li[class*="Card"] {
        flex-basis: 24%;
        margin-right: 1%;
    }
}

.allWorkflows {
    padding: 20px 15px;
    background-color: white;
    margin-bottom: 20px;
    
    h2 {
        font-size: 16px;
        color: $dark-grey;
        font-weight: 500;
        letter-spacing: 0.5px;
        margin-bottom: 15px;
    }
}

.cardsTree {
    width: 90%;
    margin: 0 auto 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    ul {
        list-style: none;
    }
}