@import '../colors';

.LanguageTranslations {
    position: fixed;
    width: 80.9vw;
    left: 19.1vw;
    bottom: 0;
    z-index: 1;
    box-shadow:  0px 0px 5px 0px $grey;

    header {
        background-color: $light-grey;
        color: $dark-grey;
        font-weight: 500;
        letter-spacing: 0.5px;
        text-align: center;
        padding: 10px 0;
        font-size: 16px;
    }

    .TranslationsHolder {
        background-color: white;
        display: flex;
        padding: 20px 30px;
        width: 100%;
        overflow-x: scroll;
    }

    .InputHolder {
        min-width: 25%;
        position: relative;
        border: 1px solid $grey;
        border-radius: 5px;
        height: 40px;
        margin-right: 30px;

        label {
            position: absolute;
            left: 10px;
            top: -10px;
            padding: 0 10px;
            background-color: white;
            color: $grey;
            font-size: 14px;
            letter-spacing: 0.5px;
        }

        input {
            padding-left: 20px;
            border: 0px;
            display: block;
            height: 100%;
            background-color: transparent;
            font-weight: 500;
            font-size: 14px;
            color: $dark-grey;
        }
    }
}