@import '../colors';

.addLevel {
    background-color: $lighter-grey;
    padding: 8px;
    box-shadow: 0px 0px 2px lighten($grey, 25%);
    
    div[class*="inputHolder"] {
        background-color: white;
        margin-bottom: 20px;
        box-shadow: 0px 0px 2px lighten($grey, 25%);
    }
}

.submitFormButton {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    margin-top: -8px;
    box-shadow: none;
    
    background-color: $blue;
    color: white;
    text-align: center;
    font-size: 13px;
    cursor: pointer;
    
    &:focus, &:active {
        outline: none;
    }
}