.ColorProgress {
    width: 300px;
    height: 15px;
    margin-bottom: 15px;
    
    input {
        display: block;
        width: 100%;
        transform: translateY(10px);
    }
}

.HueSelector {
    composes: ColorProgress;
    background-image: linear-gradient(to right, rgb(255, 0, 0) 0%, rgb(255, 255, 0) 17%, rgb(0, 255, 0) 33%, rgb(0, 255, 255) 50%, rgb(0, 0, 255) 67%, rgb(255, 0, 255) 83%, rgb(255, 0, 0) 100%);
}

.SaturationSelector {
    composes: ColorProgress;
    background-image: linear-gradient(to right, hsl(120, 0%, 50%) 0%, hsl(120, 100%, 50%) 100%);
}

.BrightnessSelector {
    composes: ColorProgress;
    background-image: linear-gradient(to right, hsl(120, 100%, 0%) 0%, hsl(120, 100%, 50%) 50%, hsl(120, 100%, 100%) 100%);
}

.ColorComponentHolder {
    display: flex;
    align-items: center;
}

.ProgressBars {
    display: inline-block;
    vertical-align: top;
    width: 300px;
    margin-right: 30px;
}

.SelectedColor {
    display: inline-block;
    vertical-align: top;
    width: 150px;
    height: 80px;
    line-height: 80px;
    transform: translateY(-5px);
    border: none;
    box-shadow: none;
    padding: 0px;
    text-align: center;
    font-size: 18px;
    
    color: white;
    font-weight: bold;
    text-shadow: 0px 0px 4px black;
    
    &:focus {
        outline: none;
    }
}